<template>
  <div id="component-Footer" class="mt-36 py-8">
    <div class="flex justify-around items-center max-w-7xl mx-auto px-4 py-4 flex-wrap gap-3">
      <img src="@/assets/sponsors/StradaLogo.png" alt="" class=" h-12 rounded-md shadow-md">
      <img src="@/assets/sponsors/asvoeLogo.png" alt="" class=" h-12 rounded-md shadow-md">
      <img src="@/assets/sponsors/patscheiderLogo.png" alt="" class=" h-12 rounded-md shadow-md">
      <img src="@/assets/sponsors/krewalderLogo.png" alt="" class=" h-12 rounded-md shadow-md">
      <img src="@/assets/sponsors/petznerLogo.jpg" alt="" class=" h-12 rounded-md shadow-md">
      <img src="@/assets/sponsors/pistotnigLogo.png" alt="" class=" h-12 rounded-md shadow-md">
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  methods: {
    navigate (target) {
      if (this.$route.path == target) {
        this.$router.push(target)
      } else {
        console.log('same route');
      }
    }
  }
}
</script>

<style scoped>
#component-Footer{
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.6);
}
</style>
