<template>
  <div id="view-home" class="min-h-screen h-full flex flex-col">
    <top-bar/>
    <div class=" flex-grow flex flex-col">

      <div class=" flex justify-center mt-16">
        <img src="@/assets/img/TCM-Logo-Gruen.png" alt="" class=" h-48 shadowed">
      </div>
      <div id="WelcomeText" class=" justify-center px-4 mt-4">
        <p class=" text-center">Herzlich Willkommen auf der Website des TC Magdalensberg!</p>
      </div>

      <div id="News" class=" grid grid-cols-1 gap-4 px-6 mt-36">
        <div class="flex justify-center">
          <h1 class=" text-center text-4xl sm:text-6xl mb-6 font-Pacifico pb-2 w-auto text-xGreen filter text-xGreen3 drop-shadow-lg">News</h1>
        </div>
        <div class=" bg-xWhite1 rounded-md shadow-lg p-8 relative">
          <h3 class=" font-bold text-xl">Die Tennissaison 2021 ist eröffnet</h3>
          <p class=" mt-3">Die Tennisplätze wurden, wie in den vergangenen Jahren, von unseren Spielern saniert und für die neue Saison vorbereitet. Seitens des Vorstandes <button class=" text-xBlue1 font-bold" v-if="!showNews1" @click="showNews1 = true">mehr lesen...</button><span v-if="showNews1">möchten wir uns bei allen Freiwilligen für die Mitarbeit bedanken. Die jährliche Frühjahrssanierung wäre ohne den unentgeltlichen Einsatz unserer Mitglieder nicht möglich. Trotz der aktuellen Situation freuen wir uns auf eine unfallfreie und lange Tennissaison. Neue Mitglieder jeder Altersklasse und Spielstärke sind jederzeit herzlich willkommen. Ganz besonders freuen wir uns über die neue Website des TC Magdalensberg. Über diese Seite können die vier Tennisplätze erstmalig online reserviert werden. Danke an Thomas Otti und David Patscheider für die tolle Arbeit. Bis bald beim Tennis.</span></p>
        </div>
        <div class=" bg-xWhite1 rounded-md shadow-lg p-8 relative">
          <h3 class=" font-bold text-xl">Jugendarbeit im Fokus</h3>
          <p class=" mt-3">Die Jugendarbeit steht beim TC Magdalensberg seit der Vereinsgründung besonders im Fokus. Seit dem Jahr 1987 finden <button class=" text-xBlue1 font-bold" v-if="!showNews2" @click="showNews2 = true">mehr lesen...</button><span v-if="showNews2">ohne Unterbrechung Tenniskurse für Kinder statt. Die Tenniscamps in den Sommerferien sind sehr beliebt und jedes Jahr gut besucht. Die Termine für den Sommer 2021 werden demnächst festgelegt und veröffentlicht.</span></p>
        </div>
      </div>

      <div id="bilder" class=" pb-6 mt-36">
        <div class="flex justify-center">
          <h1 class=" text-center text-4xl sm:text-6xl mb-6 font-Pacifico pb-2 text-xGreen filter text-xGreen3 drop-shadow-lg">Bilder</h1>
        </div>
        <VueSlickCarousel v-bind="settings" class="">
          <div id="slide" class="px-2 h-40 sm:h-64 relative">
            <img src="@/assets/pictures/FinaleVereinsmeisterschaft2019.jpg" alt="" class="rounded-md object-contain h-full">
            <p class=" text-xs">Finale Vereinsmeisterschaft 2019</p>
          </div>
          <div id="slide" class="px-2 h-40 sm:h-64 relative">
            <img src="@/assets/pictures/FinaleVereinsmeisterschaften2021.jpeg" alt="" class="rounded-md object-contain h-full">
            <p class=" text-xs">Finale Vereinsmeisterschaft 2020</p>
          </div>
          <div id="slide" class="px-2 h-40 sm:h-64 relative">
            <img src="@/assets/pictures/Jugendarbeit.jpg" alt="" class="rounded-md object-contain h-full">
            <p class=" text-xs">Jugendarbeit</p>
          </div>
          <div id="slide" class="px-2 h-40 sm:h-64 relative">
            <img src="@/assets/pictures/Mannschaft1.jpeg" alt="" class="rounded-md object-contain h-full">
            <p class=" text-xs">Manschaft 1</p>
          </div>
          <div id="slide" class="px-2 h-40 sm:h-64 relative">
            <img src="@/assets/pictures/Mannschaft2.jpeg" alt="" class="rounded-md object-contain h-full">
            <p class=" text-xs">Mannschaft 2</p>
          </div>
          <div id="slide" class="px-2 h-40 sm:h-64 relative">
            <img src="@/assets/pictures/U11.jpeg" alt="" class="rounded-md object-contain h-full">
            <p class=" text-xs">U 11</p>
          </div>
          <div id="slide" class="px-2 h-40 sm:h-64 relative">
            <img src="@/assets/pictures/Platzsanierung2021.jpg" alt="" class="rounded-md object-contain h-full">
            <p class=" text-xs">Platzsanierung 2021</p>
          </div>
        </VueSlickCarousel>
      </div>

      <div id="Jugend" class=" grid grid-cols-1 gap-4 px-6 mt-36">
        <div class="flex justify-center">
          <h1 class=" text-center text-4xl sm:text-6xl mb-6 font-Pacifico pb-2 w-auto text-xGreen filter text-xGreen3 drop-shadow-lg">Jugendarbeit</h1>
        </div>
        <p class=" text-center">Die Jugendarbeit steht beim TC Magdalensberg seit der Vereinsgründung besonders im Fokus. Seit dem Jahr 1987 finden ohne Unterbrechung Tenniskurse für Kinder statt. Die Tenniscamps in den Sommerferien sind sehr beliebt und jedes Jahr gut besucht. Unter der Leitung von Tennisinstruktorin Lisa Rückenbaum und Übungsleiter Harald Andesner finden das ganze Jahr über Tenniskurse, Einzeltrainings sowie Schnupperstunden statt. Informationen zu den verschiedenen Angeboten gibt es bei Obmann Harald Andesner unter <span class=" font-bold text-xGreen3">0664/4346899</span> oder per Mail an <span class=" font-bold text-xGreen3">h.andesner@gmx.at</span></p>
      </div>

      <div id="Mitglied" class=" grid grid-cols-1 gap-4 px-6 mt-36">
        <div class="flex justify-center">
          <h1 class=" text-center text-4xl sm:text-6xl mb-6 font-Pacifico pb-2 w-auto text-xGreen filter text-xGreen3 drop-shadow-lg">Mitgliedschaft</h1>
        </div>
        <p class="text-center leading-8">Möchten Sie Club-Mitglied werden? <br> Nehmen Sie am besten gleich Kontakt mit <span class=" font-bold text-xGreen3">Obmann Harald Andesner ( <a class=" bg-xWhite1 p-1 rounded-md shadow-md hover:text-xRed transition-all delay-100" href="mailto:h.andesner@gmx.at">h.andesner@gmx.at</a> <a class="bg-xWhite1 p-1 rounded-md shadow-md hover:text-xRed transition-all delay-100" href="tel:+436644346899">0664/4346899</a> )</span> oder mit <span class=" font-bold text-xGreen3">Obmann Stv. Markus Bleiweiss ( <a class="bg-xWhite1 p-1 rounded-md shadow-md hover:text-xRed transition-all delay-100" href="mailto:markus.bleiweiss@gmx.at">markus.bleiweiss@gmx.at</a> <a class="bg-xWhite1 p-1 rounded-md shadow-md hover:text-xRed transition-all delay-100" href="tel:+436641137050">0664/1137050</a> )</span> auf.</p>
        <div class=" grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
          <div class=" bg-xWhite1 shadow-md rounded-md flex flex-col justify-center items-center p-8">
            <h3 class=" text-2xl font-bold text-xGreen3">Erwachsene</h3>
            <p class=" text-xs">Einzelmitglieder</p>
            <p class=" mt-2 font-bold text-5xl text-xGreen3">115 €</p>
          </div>
          <div class=" bg-xWhite1 shadow-md rounded-md flex flex-col justify-center items-center p-8">
            <h3 class=" text-2xl font-bold text-xGreen3">Erwachsene</h3>
            <p class=" text-xs">Familienmitglieder</p>
            <p class=" mt-2 font-bold text-5xl text-xGreen3">105 €</p>
          </div>
          <div class=" bg-xWhite1 shadow-md rounded-md flex flex-col justify-center items-center p-8">
            <h3 class=" text-2xl font-bold text-xGreen3">Schüler & Studenten</h3>
            <p class=" text-xs">in Ausbildung (ab 18 Jahre)</p>
            <p class=" mt-2 font-bold text-5xl text-xGreen3">70 €</p>
          </div>
          <div class=" bg-xWhite1 shadow-md rounded-md flex flex-col justify-center items-center p-8">
            <h3 class=" text-2xl font-bold text-xGreen3">Kinder und Jugendliche</h3>
            <p class=" text-xs">bis 18 Jahre</p>
            <p class=" mt-2 font-bold text-5xl text-xGreen3">40 €</p>
          </div>
          <div class=" bg-xWhite1 shadow-md rounded-md flex flex-col justify-center items-center p-8 col-span-1 sm:col-span-2">
            <h3 class=" text-2xl font-bold text-xGreen3">Gastspieler</h3>
            <p class=" text-xs">Platzgebühr</p>
            <p class=" mt-2 font-bold text-5xl text-xGreen3">10 €/h</p>
            <p class=" text-center mt-4">Gastspieler können den Schlüssel zu den Tennisplätzen im Cafe alla Strada, beim Buffet am Pischeldorfer Badeteich oder im Gasthof Kurath während der jeweiligen Öffnungszeiten ausleihen.</p>
          </div>
        </div>
        <p class="mt-6 text-center"><span class=" font-bold text-xGreen3">Bankverbindung:</span> RAIKA Grafenstein Magdalensberg, Bankstelle Lassendorf (IBAN: AT47 3932 0000 0010 6682) <br> Neumitglieder bitte Name, Adresse, Geburtsjahr und E-Mail-Adresse od. Telefonnummer angeben.</p>
        <p class=" text-center">Nach Einlangen des Mitgliedsbeitrages wird ein Schlüssel für den Zutritt zu den Tennisplätzen gegen eine Kaution von € 10,-- ausgehändigt. Kontaktaufnahme Christian Patscheider (Tel. 0664/3419877) oder Harald Andesner (Tel. 0664/4346899).</p>
      </div>

      <div id="Mannschaften" class=" grid grid-cols-1 gap-4 px-6 mt-36">
        <div class="flex justify-center">
          <h1 class=" text-center text-4xl sm:text-6xl mb-6 font-Pacifico pb-2 w-auto text-xGreen filter text-xGreen3 drop-shadow-lg">Mannschaften</h1>
        </div>
        <div class="flex justify-center mb-6">
          <a class=" text-center text-xRed p-4 shadow-md rounded-md bg-xWhite1 hover:bg-xRed hover:text-xWhite1 transition-all duration-500" href="https://www.tenniskaernten.at/liga/vereine/verein/mannschaften/v/90794.html?cHash=91e2d718a0110b6bf8f22cfe3cc159cc">Hier gehts zum <span class=" font-bold">Kärntner Tennisverband</span></a>
        </div>
        <div class=" grid grid-cols-1 sm:grid-cols-2 w-full">
          <div class=" flex justify-end">
            <img src="@/assets/pictures/Mannschaft1.jpeg" alt="" class=" h-60 object-contain rounded-md">
          </div>
          <div class="pl-4">
            <h3 class=" font-bold text-xGreen3 text-xl">Mannschaft 1</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
          </div>
        </div>
        <div class=" grid grid-cols-1 sm:grid-cols-2 pt-10">
          <div class=" text-right pr-4">
            <h3 class=" font-bold text-xGreen3 text-xl">Mannschaft 2</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
          </div>
          <div class=" flex justify-start">
            <img src="@/assets/pictures/Mannschaft2.jpeg" alt="" class=" h-60 object-contain rounded-md">
          </div>
        </div>
        <div class=" grid grid-cols-1 sm:grid-cols-2 pt-10">
          <div class=" flex justify-end">
            <img src="@/assets/pictures/U11.jpeg" alt="" class=" h-60 object-contain rounded-md">
          </div>
          <div class="pl-4">
            <h3 class=" font-bold text-xGreen3 text-xl">U 11</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
          </div>
        </div>
      </div>

      <div id="App" class=" grid grid-cols-1 gap-4 px-6 mt-36">
        <div class="flex justify-center">
          <h1 class=" text-center text-4xl sm:text-6xl mb-6 font-Pacifico pb-2 w-auto text-xGreen filter text-xGreen3 drop-shadow-lg">App</h1>
        </div>
        <p class=" text-center">Mit hilfe der neuen Platz-Reservierungs-App des TC Magdalensberg können Sie einfach und unkompliziert einen Tennisplatz reservieren.</p>
        <div class=" flex justify-center mt-2"><a href="https://app.tc-magdalensberg.at" class=" text-center text-xRed p-4 shadow-md rounded-md bg-xWhite1 hover:bg-xRed hover:text-xWhite1 transition-all duration-500">Hier gehts zur App!</a></div>
      </div>

      <div id="Anfahrt" class=" grid grid-cols-1 gap-4 mt-36">
        <div class="flex justify-center">
          <h1 class=" text-center text-4xl sm:text-6xl mb-6 font-Pacifico pb-2 w-auto text-xGreen filter text-xGreen3 drop-shadow-lg">Anfahrt</h1>
        </div>
        <div>
          <p class=" text-center">Görtschitztal Straße 230</p>
          <p class=" text-center mb-6">9064 Pischeldorf</p>
          <div style='height:300px;width:100%;'><iframe width="" height="300" src="https://maps.google.de/maps?hl=de&q=%20Görtschitztal+Str.+230%20Pischeldorf&t=h&z=14&ie=utf8&iwloc=b&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" style='height:300px;width:100%;'> Von <a href="http://check-gutschein.de"  style="font-size:10px;" title="check" target="_blank">check</a></iframe><p style="text-align:right; margin:0px; padding-top:-10px; line-height:10px;font-size:10px;margin-top: -25px;"> von <a href="https://p2p-bonus.com"  style="font-size:10px;" title="P2P" target="_blank">P2P</a></p></div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>  
</template>

<script>
import TopBar from '../components/TopBar.vue'
import Footer from '../components/Footer.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  data() {
    return {
      showNews1: false,
      showNews2: false,
      settings: {
        "arrows": false,
        "dots": true,
        "infinite": true,
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "pauseOnDotsHover": true,
        "autoplay": true,
        "speed": 6000,
        "autoplaySpeed": 0,
        "cssEase": "linear",
        "variableWidth": true,
        "swipeToSlide": true,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      }
    };
  },
  computed: {
    test(){
      return 'test'
    },
  },
  components: {
    TopBar,
    Footer,
    VueSlickCarousel
  },
  methods: {
    test2(){
      console.log('hello');
    }
  },
  beforeCreate(){},
  beforeMount() {},
}
</script>

<style scoped lang="scss">
#view-home{
  //background-image: url("../assets/img/bg-2.jpg");
  //background-position: center;
  //background-repeat: no-repeat;
  //background-size: cover;
}
.shadowed {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(34, 34, 34, 0.5));
  filter: drop-shadow(5px 5px 5px rgba(34, 34, 34, 0.5));
}
</style>

