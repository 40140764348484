<template>
  <div id="component-TopBar" class=" sticky">
    <div class="flex justify-between items-stretch max-w-7xl mx-auto px-4">
      <button class=" outline-none" @click="navigate('/Home')">
        <img src="@/assets/img/TCM-Logo-Gruen.png" alt="" class="h-10 p-1">
      </button>
      <div class="flex items-stretch">
        <a href="https://app.tc-magdalensberg.at" class="flex items-center px-3 hover:text-xGreen3"><p class="">App</p></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  methods: {
    navigate (target) {
      if (this.$route.path == target) {
        this.$router.push(target)
      } else {
        console.log('same route');
      }
    }
  }
}
</script>

<style scoped>
#component-TopBar{
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.6);
}
</style>
